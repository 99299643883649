import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeOneComponent} from './components/home-one/home-one.component';
import {ProfilJonathanComponent} from "./components/profil-jonathan/profil-jonathan.component";
import {ProfilDamienComponent} from "./components/profil-damien/profil-damien.component";
import {DescMaintenanceComponent} from "./components/desc-maintenance/desc-maintenance.component";
import {DescBureauComponent} from "./components/desc-bureau/desc-bureau.component";
import {DescMaintenanceCremComponent} from "./components/desc-maintenance-crem/desc-maintenance-crem.component";
import {MentionsComponent} from "./components/mentions/mentions.component";

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'profilJo', component: ProfilJonathanComponent},
    {path: 'profilDa', component: ProfilDamienComponent},
    {path: 'descMaintenance', component: DescMaintenanceComponent},
    {path: 'descMaintenanceCrem', component: DescMaintenanceCremComponent},
    {path: 'descBureau', component: DescBureauComponent},
    {path: 'mentions', component: MentionsComponent},

    // {path: '**', component: HomeOneComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top',})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
