import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(
        private router: Router,
        private viewportScroller: ViewportScroller,
        private gtmService: GoogleTagManagerService
    ) {}

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    public onClick(elementId: string): void {
        this.gtmService.pushTag({
            event: 'button_click',
            pagePath: 'button-' + elementId
        });
        this.router.navigate(['/'])
        setTimeout(() => {
            this.viewportScroller.scrollToAnchor(elementId);
        }, 0);
    }

    ngOnInit() {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
