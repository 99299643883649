<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>A propos de <span>Ténéo Ind.</span></h2>
            <p style="max-width: none;">
                "Fondée par deux <b>passionnés</b> de la maintenance industrielle, TENEO INDUSTRIE est une société nouvellement créée,
                spécialisée dans la maintenance des crématoriums et des groupes industriels.
                <br>Sa particularité réside dans sa capacité à appréhender les exigences de sa clientèle.
            </p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Nos domaines <span>d'expertise et d'applications</span></h2>
                        <p>
                            Nous proposons des solutions personnalisées qui répondent de manière spécifique aux défis rencontrés tous les jours.
                            S’appuyant sur de solides partenariats nationaux, TENEO INDUSTRIE est résolument tournée vers l'innovation,
                            tout en demeurant ancrée dans ses valeurs fondamentales de qualité, d'intégrité et de service client.
                        </p>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fas fa-check"></i>
                            <span>Maintenance Industrielle</span>
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            <span>Maintenance Crématorium</span>
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            <span>Audits des installations</span>
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            <span>Bureau d'études</span>
                        </li>
                        <li>
                            <i class="fas fa-check"></i>
                            <span>Sécurisation des installations et des locaux</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/_18ad0f2d-571a-4b09-b38b-02d3c5ee03a2_v1.png" style="height: 400px" alt="about">
                </div>
            </div>
        </div>
    </div>
</div>
