import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mentions',
  templateUrl: './mentions.component.html',
  styleUrl: './mentions.component.scss'
})
export class MentionsComponent  implements OnInit {
    ngOnInit() {
    }
}
