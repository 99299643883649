import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

    constructor(
        private viewportScroller: ViewportScroller,
        private router: Router,
        private gtmService: GoogleTagManagerService
    ) {}

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    routeProfilJo() {
        this.gtmService.pushTag({
            event: 'button_click',
            pagePath: 'button-profil-jonathan'
        });
        this.router.navigate(['profilJo']);
    }

    routeProfilDa() {
        this.gtmService.pushTag({
            event: 'button_click',
            pagePath: 'button-profil-damien'
        });
        this.router.navigate(['profilDa']);
    }

    teamSlides: OwlOptions = {
		loop: false,
		nav: false,
		dots: true,
		autoplayHoverPause: false,
		autoplay: false,
		margin: 30,
		navText: [
			"<i class='fa fa-angle-left'></i>",
			"<i class='fa fa-angle-right'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 3,
			}
		}
    }

}
