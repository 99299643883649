import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CountUpModule} from 'ngx-countup';
import {NgxTypedJsModule} from 'ngx-typed-js';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {HomeOneComponent} from './components/home-one/home-one.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {WelcomeComponent} from './components/common/welcome/welcome.component';
import {AboutComponent} from './components/common/about/about.component';
import {StrategyComponent} from './components/common/strategy/strategy.component';
import {WhoWeAreComponent} from './components/common/who-we-are/who-we-are.component';
import {CtaComponent} from './components/common/cta/cta.component';
import {SkillComponent} from './components/common/skill/skill.component';
import {FunfactsComponent} from './components/common/funfacts/funfacts.component';
import {TeamComponent} from './components/common/team/team.component';
import {HowWeWorkComponent} from './components/common/how-we-work/how-we-work.component';
import {ServicesComponent} from './components/common/services/services.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {SubscribeComponent} from './components/common/subscribe/subscribe.component';
import {PartnerComponent} from './components/common/partner/partner.component';
import {BlogComponent} from './components/common/blog/blog.component';
import {FeedbackComponent} from './components/common/feedback/feedback.component';
import {FaqComponent} from './components/common/faq/faq.component';
import {PricingComponent} from './components/common/pricing/pricing.component';
import {WorkComponent} from './components/common/work/work.component';
import {WhyWeDifferentComponent} from './components/common/why-we-different/why-we-different.component';
import {ContactComponent} from './components/common/contact/contact.component';
import {ServicesTwoComponent} from './components/common/services-two/services-two.component';
import {BlogDetailsComponent} from './components/blog-details/blog-details.component';
import {DemoSidebarComponent} from './components/common/demo-sidebar/demo-sidebar.component';
import {Strategy2Component} from "./components/common/strategy2/strategy2.component";
import {ProfilJonathanComponent} from "./components/profil-jonathan/profil-jonathan.component";
import {ProfilDamienComponent} from "./components/profil-damien/profil-damien.component";
import {DescMaintenanceComponent} from "./components/desc-maintenance/desc-maintenance.component";
import {DescBureauComponent} from "./components/desc-bureau/desc-bureau.component";
import {Strategy3Component} from "./components/common/strategy3/strategy3.component";
import {HttpClientModule} from "@angular/common/http";
import {SafeHtmlPipe} from "./pipe/safe-html.pipe";
import {DescMaintenanceCremComponent} from "./components/desc-maintenance-crem/desc-maintenance-crem.component";
import {MentionsComponent} from "./components/mentions/mentions.component";
import {GoogleTagManagerModule} from 'angular-google-tag-manager';

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        Strategy2Component,
        Strategy3Component,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        ServicesTwoComponent,
        BlogDetailsComponent,
        DemoSidebarComponent,
        ProfilJonathanComponent,
        ProfilDamienComponent,
        DescMaintenanceComponent,
        DescBureauComponent,
        SafeHtmlPipe,
        DescMaintenanceCremComponent,
        MentionsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxScrollTopModule,
        CarouselModule,
        ReactiveFormsModule,
        CountUpModule,
        NgxTypedJsModule,
        HttpClientModule,
        GoogleTagManagerModule.forRoot({id: 'G-RFMXWW1DXG'}),
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
