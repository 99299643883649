import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-strategy2',
    templateUrl: './strategy2.component.html',
    styleUrls: ['./strategy2.component.scss']
})
export class Strategy2Component implements OnInit {

    constructor(
        private viewportScroller: ViewportScroller,
        private router: Router,
        private gtmService: GoogleTagManagerService
    ) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    goToBureauDescPage() {
        this.gtmService.pushTag({
            event: 'button_click',
            pagePath: 'button-description-maintenance-crematorium'
        });
        this.router.navigate(['descMaintenanceCrem']);
    }

}
