import {Component, OnInit} from '@angular/core';
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-desc-maintenance',
    templateUrl: './desc-maintenance-crem.component.html',
    styleUrls: ['./desc-maintenance-crem.component.scss']
})
export class DescMaintenanceCremComponent implements OnInit {

    data: any;

    constructor(
        private gtmService: GoogleTagManagerService
    ) {
    }

    ngOnInit() {
        this.gtmService.pushTag({
            event: 'page_view',
            pagePath: 'description-maintenance-crematorium'
        });
    }

}
