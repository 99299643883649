<nav class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
<!--        <a class="navbar-brand" routerLink="/"><span>T</span>éneo <span>I</span>ndustrie</a>-->
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logobleu.png" alt="client">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Accueil</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">A propos</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('maintenance')">La maintenance</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('bureau')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('team')">L'équipe</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Accueil</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">A propos</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('maintenance')">La maintenance</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('bureau')">Services</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">L'équipe</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contact</span></li>
            </ul>
        </div>
    </div>
</nav>
