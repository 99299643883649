<div class="page-title-area item-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>L'équipe Ténéo Ind.</h1>
                    <p style="color: white">Focus sur </p>
                    <!--                    <ul>-->
                    <!--                        <li><a routerLink="/">Home</a></li>-->
                    <!--                        <li><i class="fas fa-chevron-right"></i></li>-->
                    <!--                        <li class="active">Blog</li>-->
                    <!--                    </ul>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <!--                    <div class="article-img">-->
                    <!--                        <img src="assets/img/_cd3323d1-6327-497d-9015-d2d5663b2f9a.jpeg" alt="blog-details">-->
                    <!--&lt;!&ndash;                        <div class="date">20 <br> Dec</div>&ndash;&gt;-->
                    <!--                    </div>-->
                    <div class="article-text">

                        <div class="author-share">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/_f2fdc49b-573e-4715-8862-91ddba73a9b4 copie.jpg"
                                                 alt="client">
                                        </div>
                                        <div class="author-title">
                                            <h4>Jonathan Hellé</h4>
                                            <!--                                            <span>Back-End Developer</span>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ul class="social-share">
                                        <!--                                        <li><a href="#" tooltip="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>-->
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Twitter"><i class="fab fa-twitter"></i></a></li>-->
                                        <li><a href="https://www.linkedin.com/in/jonathan-hell%C3%A9-b66172302/"
                                               target="_blank" tooltip="Share on Linkedin"><i
                                            class="fab fa-linkedin-in"></i></a></li>
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Instagram"><i class="fab fa-instagram"></i></a></li>-->
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <ul class="category">
                            <li><a routerLink="/">CEO</a></li>
                            <li><a routerLink="/">CO-FONDATEUR</a></li>
                        </ul>
                        <br><br>
                        <!--                        <h4 class="title">-->
                        <!--                            {{ data.name }} (<a href="{{ data.linkedin }}" target="_blank" tooltip="Share on Linkedin"><i class="fab fa-linkedin"></i></a>)-->
                        <!--                        </h4>-->

                        <p>
                            A l’issue d’un Brevet d’études professionnelles en <b>Electrotechnique</b>, Jonathan HELLÉ
                            débute sa carrière d’automaticien au sein d’une société sous-traitante d’un fabricant
                            d’appareils de crémation et participe notamment à la fabrication des armoires électriques.
                        </p>

                        <p>
                            En 2012, il intègre le Groupe d’ingénierie industrielle Fives Nordon, spécialisé dans la
                            conception et la réalisation de machines, en tant que <b>responsable</b> d’atelier. A ce
                            titre, il réalise plusieurs missions telles que la planification et la gestion des
                            commandes, la gestion des équipes et l’intervention sur les sites type CNPE (Centrale de
                            Production Electrique).
                        </p>

                        <p>
                            En 2016, Jonathan HELLÉ rejoint une société experte en machines spéciales. Il est ainsi
                            amené à réaliser des <b>opérations de mise en route</b> et de câblage des appareils de
                            crémation et de filtration. <b>Automaticien aguerri</b>, il s’installe à son compte en 2018
                            et crée une société spécialisée dans le secteur d’activité des travaux d’installation
                            électrique et de maintenance industrielle. Dans ce cadre, Jonathan HELLE effectue des
                            dépannages sur les équipements de crémation comme sous-traitant d’un fabricant d'appareils
                            de crémation pendant trois ans avant de rejoindre l’équipe de PRODESTIS en 2020 en tant que
                            Responsable Maintenance Secteur Est.
                        </p>

                        <p>
                            C’est aujourd’hui en tant qu'<b>expert</b> de la maintenance industrielle, que Jonathan <b>préside</b>
                            la société TENEO-INDUSTRIE.
                        </p>

                        <blockquote class="blockquote">
                            <p class="mb-0">Ne rêvez pas de réussite, travaillez pour l'atteindre. (JH)</p>
                        </blockquote>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
