import {Component, OnInit} from '@angular/core';
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-desc-maintenance',
    templateUrl: './desc-maintenance.component.html',
    styleUrls: ['./desc-maintenance.component.scss']
})
export class DescMaintenanceComponent implements OnInit {

    constructor(
        private gtmService: GoogleTagManagerService
    ) {
    }

    ngOnInit() {
        this.gtmService.pushTag({
            event: 'page_view',
            pagePath: 'description-maintenance'
        });
    }

}
