<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Qui sommes <span>nous ?</span></h2>
            <p style="max-width: none;">
                Une équipe de professionnels et de passionnés toujours à la recherche de nouveaux matériels
                et process innovants avec des techniciens expérimentés et compétents. Un audit et conseil neutre à votre écoute.
            </p>
        </div>
        <div class="row">

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-building"></i>
                    <h3>Des Professionnels</h3>
                    <p>Expérimentés et prêts à relever tous les défis dans notre domaine d'expertise afin de garantir la satisfaction clients.</p>
                    <span>1</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-gears"></i>
                    <h3>Des Passionnés</h3>
                    <p>Dévoués à préserver la durabilité des équipements avec détermination et savoir-faire.</p>
                    <span>2</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Innovants</h3>
                    <p>La R&D est un point important pour nous afin d’optimiser et améliorer la productivité opérationnelle et prévenir les pannes.</p>
                    <span>3</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-hammer"></i>
                    <h3>Des Techniciens</h3>
                    <p>Compétents et polyvalents, capables de résoudre les problèmes techniques avec expertise et précision.</p>
                    <span>4</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-briefcase"></i>
                    <h3>Audit / Conseil</h3>
                    <p>Nous vous accompagnons sur des propositions d'amélioration et d’efficacité sans oublier la sécurité, point important pour nous</p>
                    <span>5</span>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fa-solid fa-headset"></i>
                    <h3>A Votre Ecoute</h3>
                    <p>Un suivi personnalisé, une assistance technique disponible et des spécialistes pour répondre à vos besoins en matière de maintenance.</p>
                    <span>6</span>
                </div>
            </div>

        </div>
    </div>
</div>
