<div class="page-title-area item-bg-maint">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Single Post</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="../../../assets/img/What-Is-A-Scrum-Board-.png" alt="blog-details">
<!--                        <div class="date">20 <br> Dec</div>-->
                    </div>
                    <div class="article-text">
                        <ul class="category">
                            <li><a routerLink="/">Agilité</a></li>
                            <li><a routerLink="/">Réactivité</a></li>
                            <li><a routerLink="/">Expertise</a></li>
                            <li><a routerLink="/">La meilleure solution</a></li>
                        </ul>
                        <h1 class="title">
                            Bureau d'études
                        </h1>

                        <p>
                            Afin d'améliorer à la fois la productivité et la durabilité de vos équipements, nous vous proposons une optimisation de leurs fonctionnalités. Notre approche consiste à identifier les axes d'amélioration pour ensuite vous présenter des solutions novatrices.
                        </p>

                        <blockquote class="blockquote">
                            <p class="mb-0">
                                Tout n'est pas à changer, nous vous apportons la meilleure solution à votre projet aussi bien en terme de coûts, délais.
                            </p>
                            <p class="mb-0" style="float: right">Jonathan et Damien</p>
                            <br/>
                        </blockquote>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
