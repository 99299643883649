<div id="maintenance" class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/maintenance-usine-du-futur.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Tous Types De Maintenance</h4>
                        <h2>La <span>Maintenance</span> Industrielle</h2>
                        <p>Nous Distinguons 3 grandes catégories de maintenance, pouvant elles mêmes se décliner en sous
                            catégories</p>
                    </div>
                    <br><br>
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="single-how-work">
                                <div class="icon">
                                    <i class="fa-solid fa-clock-rotate-left"></i>
                                </div>
                                <h3>Maintenance &nbsp;&nbsp;Préventive</h3>
                                <!--                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>-->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="single-how-work">
                                <div class="icon">
                                    <i class="fa-solid fa-arrow-up-right-dots"></i>
                                </div>
                                <h3>Maintenance &nbsp;Améliorative</h3>
                                <!--                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>-->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="single-how-work">
                                <div class="icon">
                                    <i class="fas fa-hammer"></i>
                                </div>
                                <h3>Maintenance &nbsp;&nbsp;Corrective</h3>
                                <!--                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>-->
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                        </div>
                    </div>

                    <br> <br>
                    <a (click)="goToMaintenanceDescPage()" class="btn btn-primary">En détails</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
