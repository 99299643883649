import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import emailjs from '@emailjs/browser';
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactForm: FormGroup;
    displaySuccess: boolean;
    displayFail: boolean;
    serviceId: string;
    templateId: string;
    publicKey: string;

    constructor(
        private viewportScroller: ViewportScroller,
        private builder: FormBuilder,
        private http: HttpClient,
        private gtmService: GoogleTagManagerService
    ) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        // init value
        this.displaySuccess = false;
        this.displayFail = false;
        this.serviceId = 'service_mlmb1p9';
        this.templateId = 'template_qj6x77e';
        this.publicKey = '01NjhgUp-xgTmJzv-';

        // init contact form
        this.contactForm = this.builder.group({
            name: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(4)])]),
            email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
            phoneNumber: new FormControl(''),
            subject: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(4)])]),
            society: new FormControl(''),
            message: new FormControl('', [Validators.compose([Validators.required, Validators.minLength(4)])])
        });
    }

    onSubmit() {

        const gtmTag = { event: 'email', 'status': 'none' };

        // Get form value and construct params to send to emailJS
        const templateParams = {
            name: this.contactForm.get('name').value,
            email: this.contactForm.get('email').value,
            phoneNumber: this.contactForm.get('phoneNumber').value,
            society: this.contactForm.get('society').value,
            subject: this.contactForm.get('subject').value,
            message: this.contactForm.get('message').value,
        };

        // Send the mail to emailJS (teneo.industrie.site@gmail.com)
        emailjs.send(this.serviceId, this.templateId, templateParams,{ publicKey: this.publicKey })
            .then(
                (response) => {
                    // console.log('SUCCESS!', response.status, response.text);
                    this.displaySuccess = true;
                    this.displayFail = false;
                    this.contactForm.reset();
                    gtmTag.status = 'success';
                    this.gtmService.pushTag(gtmTag);
                },
                (err) => {
                    // console.log('FAILED...', err);
                    this.displaySuccess = false;
                    this.displayFail = true;
                    gtmTag.status = 'fail';
                    this.gtmService.pushTag(gtmTag);
                },
            );
    }

}
