<div class="page-title-area item-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>L'équipe Ténéo Ind.</h1>
                    <p style="color: white">Focus sur </p>
                    <!--                    <ul>-->
                    <!--                        <li><a routerLink="/">Home</a></li>-->
                    <!--                        <li><i class="fas fa-chevron-right"></i></li>-->
                    <!--                        <li class="active">Blog</li>-->
                    <!--                    </ul>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <!--                    <div class="article-img">-->
                    <!--                        <img src="assets/img/_cd3323d1-6327-497d-9015-d2d5663b2f9a.jpeg" alt="blog-details">-->
                    <!--&lt;!&ndash;                        <div class="date">20 <br> Dec</div>&ndash;&gt;-->
                    <!--                    </div>-->
                    <div class="article-text">

                        <div class="author-share">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6">
                                    <div class="article-author">
                                        <div class="author-img">
                                            <img src="assets/img/_cd3323d1-6327-497d-9015-d2d5663b2f9a copie.jpg"
                                                 alt="client">
                                        </div>
                                        <div class="author-title">
                                            <h4>Damien Raoult</h4>
                                            <!--                                            <span>Back-End Developer</span>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <ul class="social-share">
                                        <!--                                        <li><a href="#" tooltip="Share on Facebook"><i class="fab fa-facebook-f"></i></a></li>-->
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Twitter"><i class="fab fa-twitter"></i></a></li>-->
                                        <li><a href="https://www.linkedin.com/in/damien-raoult-003a4320a/"
                                               target="_blank" tooltip="Share on Linkedin"><i
                                            class="fab fa-linkedin-in"></i></a></li>
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Instagram"><i class="fab fa-instagram"></i></a></li>-->
                                        <!--                                        <li><a href="#" target="_blank" tooltip="Share on Vimeo"><i class="fab fa-vimeo-v"></i></a></li>-->
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        <ul class="category">
                            <li><a routerLink="/">CEO</a></li>
                            <li><a routerLink="/">CO-FONDATEUR</a></li>
                        </ul>
                        <br><br>
                        <!--                        <h4 class="title">-->
                        <!--                            {{ data.name }} (<a href="{{ data.linkedin }}" target="_blank" tooltip="Share on Linkedin"><i class="fab fa-linkedin"></i></a>)-->
                        <!--                        </h4>-->

                        <p>
                            Après avoir obtenu un Brevet d’études professionnelles en <b>Electrotechnique</b> et un
                            Baccalauréat professionnel en <b>Maintenance des Systèmes Mécaniques Automatisés</b>, il
                            débute sa carrière avec trois ans d’expérience en tant que Technicien de Maintenance au sein
                            du Technicentre Atlantique de la SNCF et sept ans au sein d’une entreprise leader dans la
                            fabrication d’appareils de crémation et filtration.
                        </p>

                        <p>
                            Damien RAOULT devient Responsable Maintenance au sein de la société d’exploitation de
                            crématoriums animaliers. Il réintègre ensuite le fabricant d’appareils de crémation au poste
                            de Responsable du Service Après-Vente Crémation et gère à ce titre, pendant deux années, une
                            équipe de dix personnes réparties sur la France entière avant de rejoindre PRODESTIS en
                            2020. Fort de ses nombreuses années d’expériences sur le terrain, Damien RAOULT
                            met désormais son expertise au profit de la société de maintenance TENEO-INDUSTRIE.
                        </p>

                        <blockquote class="blockquote">
                            <p class="mb-0">
                                On améliore rarement la qualité en diminuant les couts, mais on peut
                                souvent diminuer les couts en améliorant la qualité – Karl Albrecht
                            </p>
                        </blockquote>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
