import {Component, OnInit} from '@angular/core';
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-profil-jonathan',
    templateUrl: './profil-jonathan.component.html',
    styleUrls: ['./profil-jonathan.component.scss']
})
export class ProfilJonathanComponent implements OnInit {

    constructor(
        private gtmService: GoogleTagManagerService
    ) {
    }

    ngOnInit() {
        this.gtmService.pushTag({
            event: 'page_view',
            pagePath: 'profil-jonathan'
        });
    }

}
