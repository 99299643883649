<div class="page-title-area item-bg-maint">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Zoom sur la Maintenance Industrielle</h1>
<!--                    <ul>-->
<!--                        <li><a routerLink="/">Home</a></li>-->
<!--                        <li><i class="fas fa-chevron-right"></i></li>-->
<!--                        <li class="active">Blog</li>-->
<!--                    </ul>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/type-maintenance-schema.png" alt="blog-details">
<!--                        <div class="date">20 <br> Dec</div>-->
                    </div>
                    <div class="article-text">
                        <ul class="category">
                            <li><a routerLink="/">Anticiper</a></li>
                            <li><a routerLink="/">Prévenir</a></li>
                            <li><a routerLink="/">Réparer</a></li>
                            <li><a routerLink="/">Améliorer</a></li>
                        </ul>
                        <h1 class="title">"La maintenance et ses déclinaisons</h1>

                        <span>
                            <h4>Maintenance Préventive</h4>
                            <p style="padding-top: 15px;">
                                Anticiper et prévenir les pannes et les défaillances des équipements en réalisant des interventions
                                planifiées régulières.
                                <br><br>
                                <b>Maintenance systématique :</b> La maintenance préventive
                                systématique inclut les actions de maintenance requises par les dispositions légales et/ou réglementaires.
                                Elle inclut au minimum la planification formelle,
                                la description claire et précise du travail à effectuer (lubrification, changement de filtres, nettoyage, etc.)
                                et l’enregistrement du travail accompli.
                                <br><br>
                                La maintenance préventive systématique
                                s’applique à des mécanismes de dégradation dont l’évolution est globalement connue.
                                Ceci explique qu’elle n’inclut pas d’observation préalable de l’état du bien.
                            </p>
                        </span>

                        <span>
                            <h4>Maintenance conditionnelle et prévisionnelle</h4>
                            <p style="padding-top: 15px;">
                                Pour mieux tenir compte de la dégradation réelle du matériel/équipement (par exemple : des conditions réelles d’exploitation)
                                des mesures périodiques ou continues de paramètres observables et significatifs de l’état de dégradation
                                du bien permettent d’espacer ou de supprimer des tâches répétitives, coûteuses et parfois non justifiées.
                                <br><br>
                                La maintenance préventive conditionnelle ou prévisionnelle suppose ici l’idée de ne pas réaliser une action de
                                maintenance sur un équipement tant qu’il n’est pas sur le point de ne plus assurer sa
                                fonction requise. Ceci pour tenir compte du fait que la durée de vie de certains équipements peut diminuer
                                si ces derniers sont arrêtés et redémarrés trop fréquemment ou s’ils sont démontés plus que nécessaire.
                                Elle peut aussi permettre de réduire la fréquence de certaines actions de maintenance préventive qui nécessitent
                                l’arrêt ou le démontage des équipements.<br><br> La maintenance conditionnelle ou prévisionnelle représente une
                                démarche d’optimisation de la maintenance préventive systématique, basée sur la mesure objective de paramètres de
                                la dégradation du bien.
                            </p>
                        </span>

                        <span>
                            <h4>Maintenance Corrective</h4>
                            <p style="padding-top: 15px;">
                               Intervenir sur un équipement après qu'une panne ou une défaillance ait été constatée, afin de le remettre en
                                état de fonctionnement. <br>La maintenance corrective vise à rétablir un équipement dans l’état d’accomplir une
                                fonction requise, au moins provisoirement et/ou partiellement.
                                <br>
                                La maintenance corrective est, par définition, imprévisible mais pas forcément imprévue :
                                Maintenance corrective «palliative» Action de maintenance corrective destinée à permettre à un bien
                                d’accomplir provisoirement tout ou partie d’une fonction requise. <br>Appelée couramment «dépannage»,
                                la maintenance palliative est principalement constituée d’actions à caractère provisoires qui doivent
                                être suivies d’actions curatives. Maintenance corrective «curative» Action de maintenance corrective
                                ayant pour objet de rétablir un équipement dans un état spécifié pour lui permettre d’accomplir une fonction requise.
                                <br>
                                Le résultat des actions réalisées doit présenter un caractère permanent. Des modifications et améliorations peuvent
                                être apportées, afin de réduire l’occurrence d’apparition de la défaillance ou d’en limiter l’incidence.
                            </p>
                        </span>

                        <span>
                            <h4>Maintenance améliorative</h4>
                            <p style="padding-top: 15px;">
                               La maintenance améliorative a pour but d’optimiser l'efficacité, la qualité et la rentabilité des opérations
                                fonctions d’un équipement en identifiant et en mettant en œuvre des actions d’améliorations continues
                                du process afin d’accroitre la qualité productive, financière et d’exploitation de celui-ci.
                            </p>
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
