<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Restons en contact</h4>
            <h2>Nous <span>Contacter</span></h2>
            <p>Pour un devis, une question, une urgence, veuillez trouvez ci-dessous un formulaire de contact.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="contact-form">

                    <!--MESSAGE SUCCESS AND ERROR-->
                    <div *ngIf="displaySuccess" class="alert alert-success" role="alert">
                        Votre message a bien été envoyé, nous nous engagons à vous répondre dans les meilleurs délais.
                    </div>
                    <div *ngIf="displayFail" class="alert alert-danger" role="alert">
                        Nous avons rencontré un problème pour l'envoi de votre mail.
                        <br>Vous pouvez nous contacter directement : <a href="mailto:contact@teneo-industrie.fr">contact&#64;teneo-industrie.fr</a>.
                        <br>Nous nous excusons pour la gêne occasionnée.
                    </div>

                    <!--CONTACT FORM-->
                    <form [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Votre nom *</label>
                                    <input type="text" name="name" formControlName="name" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)">
                                        Le nom est obligatoire
                                    </div>
<!--                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">-->
<!--                                        <div *ngIf="name.errors.required">Name is required.</div>-->
<!--                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>-->
<!--                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>-->
<!--                                    </div>-->
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email *</label>
                                    <input  name="email" type="text" class="form-control" formControlName="email" id="email">
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)">
                                        L'addresse email est obligatoire et bien formatée
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Sujet *</label>
                                    <input required  name="subject" type="text" class="form-control" formControlName="subject" id="subject">
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['subject'].invalid && (contactForm.controls['subject'].dirty || contactForm.controls['subject'].touched)">
                                        Le sujet du mail est obligatoire
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Votre numéro de téléphone</label>
                                    <input required  name="number" type="text" class="form-control" formControlName="phoneNumber" id="number">
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['phoneNumber'].invalid && (contactForm.controls['phoneNumber'].dirty || contactForm.controls['phoneNumber'].touched)">
                                        Phone number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Société</label>
                                    <input required  name="subject" type="text" class="form-control" formControlName="society" id="society">
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['society'].invalid && (contactForm.controls['society'].dirty || contactForm.controls['society'].touched)">
                                        society is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message *</label>
                                    <textarea required name="message" id="message" cols="30" rows="4" formControlName="message" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched)">
                                        Le message ne peut être laissé vide
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button class="btn btn-primary" type="submit" [disabled]="!contactForm.valid" (click)="onSubmit()">Envoyez</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
<!--                    <div class="d-table">-->
                        <div class="d-table-cell">
                            <ul>
<!--                                <li><i class="fas fa-map-marker-alt"></i> <span>Localisation :</span>24 RUE DU BEL AIR 91540 MENNECY</li>-->
                                <li><i class="far fa-envelope"></i> <a href="mailto:contact&#64;teneo-industrie.fr"><span>Email:</span>contact&#64;teneo-industrie.fr</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:0184806106"><span>Téléphone :</span>(+33) 01 84 80 61 06</a></li>
<!--                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
