import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-strategy',
    templateUrl: './strategy.component.html',
    styleUrls: ['./strategy.component.scss']
})
export class StrategyComponent implements OnInit {


    constructor(
        private viewportScroller: ViewportScroller,
        private router: Router,
        private gtmService: GoogleTagManagerService
    ) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    goToMaintenanceDescPage() {
        this.gtmService.pushTag({
            event: 'button_click',
            pagePath: 'button-description-maintenance'
        });
        this.router.navigate(['descMaintenance']);
    }

    goToBureauDescPage() {
        this.router.navigate(['descBureau']);
    }

}
