import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";

@Component({
    selector: 'app-strategy3',
    templateUrl: './strategy3.component.html',
    styleUrls: ['./strategy3.component.scss']
})
export class Strategy3Component implements OnInit {

    constructor(
        private viewportScroller: ViewportScroller,
        private router: Router,
    ) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    goToBureauDescPage() {
        this.router.navigate(['descBureau']);
    }

}
