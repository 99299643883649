<div id="bureau" class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy3-image">
                    <img src="assets/img/pexels-rodeo-software-16585156.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Tous types de projets</h4>
                        <h2>Evaluation <span>Objective </span> et personalisée </h2>
                        <p>
                            Notre indépendance vous garantit une évaluation objective et approfondie des process de vos
                            installations pour assurer leur productivité et leur conformité aux normes actuelles en vigueur.
                            Grâce à notre expertise, nous offrons des solutions personnalisées et des conseils adaptés à votre situation.
                        </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-edit"></i>Changement de matériels</li>
                        <li><i class="fa fa-edit"></i>Télémaintenance</li>
                        <li><i class="fa fa-edit"></i>Nouvelles technologies</li>
                        <li><i class="fa fa-edit"></i>Projets sur mesure</li>
                        <li><i class="fa fa-edit"></i>Amelioration process</li>
                        <li><i class="fa fa-edit"></i>R&D Personalisée</li>
                    </ul>
                    <p>Quelque soit votre projet, nous vous accompagnerons de A à Z en faisant tout pour comprendre votre besoin.</p>
                    <br>
<!--                    <a (click)="goToBureauDescPage()" class="btn btn-primary">En détails</a>-->
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
