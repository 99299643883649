<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span>Formules</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Occasionnel</h3>
                    <p>Une panne, une défaillance, un repamétrage de vos machines notre équipe intervient directement
                        sur votre site</p>
                    <div class="price-value">
                        <span class="currency">€</span>
                        <span class="amount">sur devis</span>
                        <!--                        <span class="month">/Per month</span>-->
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Sur toute la france</li>
                        <li><i class="fas fa-check"></i> des prix fixe (par typologie) connus à l'avance</li>
                        <li><i class="fas fa-check"></i> Rapidité d'intervention</li>
                        <li><i class="fas fa-check"></i> Efficacité</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Contactez nous</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Annuellement</h3>
                    <p>Un suivi complet de votre site, les dépannage sont compris, hors matériels. bablalbvlazlssqdd qsdqsd sqdqsd </p>
                    <div class="price-value">
                        <span class="currency">€</span>
                        <span class="amount">sur devis</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Sur toute la france</li>
                        <li><i class="fas fa-check"></i> des prix fixe (par typologie) connus à l'avance</li>
                        <li><i class="fas fa-check"></i> Rapidité d'intervention</li>
                        <li><i class="fas fa-check"></i> Efficacité</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Contactez nous</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">ETUDES</h3>
                    <p>Un suivi complet de votre site, les dépannage sont compris, hors matériels. bablalbvlazlssqdd qsdqsd sqdqsd </p>
                    <div class="price-value">
                        <span class="currency">€</span>
                        <span class="amount">sur devis</span>
                    </div>
                    <ul class="pricing-content">
                        <li><i class="fas fa-check"></i> Sur toute la france</li>
                        <li><i class="fas fa-check"></i> des prix fixe (par typologie) connus à l'avance</li>
                        <li><i class="fas fa-check"></i> Rapidité d'intervention</li>
                        <li><i class="fas fa-check"></i> Efficacité</li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Contactez nous</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
