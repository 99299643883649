<footer class="footer-area">
    <div class="container">
        <img src="assets/img/logblanc.png" style="width: 500px" alt="about">
<!--        <h3><a routerLink="/"><span>T</span>énéo <span>I</span>ndustrie</a></h3>-->
<!--        <ul>-->
<!--            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>-->
<!--        </ul>-->
        <p>© Ténéo Industrie </p>
        <a [routerLink]="['/mentions']">mentions légales</a>
    </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
