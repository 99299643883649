<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Rencontrez Ténéo Industrie</h4>
            <h2>Les fondateurs <span>Ténéo Ind.</span></h2>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/pngwing.com.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Jonathan Hellé</h3>
                            <span class="post">CEO & Co-fondateur</span>
                        </div>
                        <div class="social">
                            <a (click)="routeProfilJo()" class="btn btn-primary view-work">Voir la Bio complète</a>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/pngwing.com.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Damien Raoult</h3>
                            <span class="post">CEO & Co-fondateur</span>
                        </div>
                        <div class="social">
                            <a (click)="routeProfilDa()" class="btn btn-primary view-work">Voir la Bio complète</a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
