import {Component, OnInit} from '@angular/core';
import {GoogleTagManagerService} from "angular-google-tag-manager";

@Component({
    selector: 'app-profil-damien',
    templateUrl: './profil-damien.component.html',
    styleUrls: ['./profil-damien.component.scss']
})
export class ProfilDamienComponent implements OnInit {

    data: any;

    constructor(
        private gtmService: GoogleTagManagerService
    ) {
    }

    ngOnInit() {
        this.gtmService.pushTag({
            event: 'page_view',
            pagePath: 'profil-jonathan'
        });
    }

}
