<div class="strategy-area" xmlns="http://www.w3.org/1999/html">
    <div class="container-fluid">
        <div class="row">


            <div class="col-lg-6 col-md-12 hide-on-big-screen">
                <div class="strategy2-image">
                    <img src="assets/img/_17f52168-d79e-4df7-a439-8ae961542c72.jpeg" alt="image">
                </div>
            </div>


            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Tous Types De Maintenance</h4>
                        <h2>La <span>Maintenance </span> Crématorium</h2>
                        <p>La maintenance des crématoriums est semblable à une maintenance industrielle avec ses 3 grandes catégories.</p>
                    </div>
                    <p>
                        Celle-ci est essentielle pour assurer leur bon fonctionnement et garantir la sécurité des
                        employés et des visiteurs.
                    </p>
                    <br>
                    <p>
                        Elle comprend plusieurs aspects, tels que l'entretien régulier des appareils de crémation,
                        la vérification des systèmes de filtration et de ventilation pour prévenir les émissions nocives
                        et l'inspection des équipements de façon régulière afin éviter les pannes et les accidents.
                    </p>
                    <br>
                    <p>
                        Une maintenance rigoureuse permet également de prolonger la durée de vie des installations et de
                        respecter les normes environnementales
                        et sanitaires en vigueur.
                    </p>
                    <br>
                    <a (click)="goToBureauDescPage()" class="btn btn-primary">En détails</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 hide-on-small-screen">
                <div class="strategy2-image">
                    <img src="assets/img/_17f52168-d79e-4df7-a439-8ae961542c72.jpeg" alt="image">
                </div>
            </div>

        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
