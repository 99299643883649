<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Nous sommes des experts</h4>
            <h2>Bienvenue chez <span>Ténéo Ind.</span></h2>
            <p>Notre expertise à votre service, confiez-nous vos sites afin d'avoir l'esprit tranquille.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <h3>Présentation</h3>
                    <p>Une équipe dynamique qui maitrise son sujet </p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-hammer"></i>
                    </div>
                    <h3>Services</h3>
                    <p>Des solutions innovantes et réalisables</p>
                    <a (click)="onClick('bureau')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-mail-bulk"></i>
                    </div>
                    <h3>Contact</h3>
                    <p>Toujours là pour vous</p>
                    <a (click)="onClick('contact')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
